html, body {
  padding: 0;
  margin: 0;
}

main {
  width: 40%;
  margin: auto;
  background-color: #ffffff;
}

nav {
  height: 50px;
}

nav li {
  display: inline-block;
  width: 12.5%;
  text-align: center;
  height: 100%;
}

nav li a {
  height: 100%;
  display: inline-block;
  line-height: 100%;
  width: 100%;
  padding-top: 15px;
  color: #00F000;
  background-color: #5200A3;
  border: 2px solid #00F000;
  border-left: none;
  text-decoration: none;
  font-weight: bold;
}

nav li a:hover, a:focus {
  color: #5200A3;
  background-color: #00F000;
  border: 2px solid #5200A3;
}

ul {
  margin: 0;
  height: 100%;
  padding-left: 0;
}

* {
  box-sizing: border-box;
}

label {
  width: 100%;
  display: inline-block;
  margin-top: 0.5rem;
}

button {
  display: block;
  margin-top: 1rem;
}

.extraLabel {
  font-size: 0.85rem;
  width: 100%;
  display: inline-block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

textarea {
  width: 70%;
  height: 50px;
}

.frogContainer {
  overflow: auto;
}

.frogSpeech {
  float: left;
}

.talking {
  border-radius: 65px;
  width: 400px;
  padding: 5px;
  text-align: center;
  color: #ffffff;
  background-color: #000000;
  border: 5px solid #00F000;
  font-size: 1.5rem;
}

.frog {
  float: left;
  margin-right: 10px;
}

button {
  color: #5200A3;
  background-color: #00F000;
  border: 5px solid #5200A3;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  font-size: 1rem;
  margin-bottom: 50px;
}

button:hover, button:focus {
  color: #00F000;
  background-color: #5200A3;
  border: 5px solid #00F000;
}

@media only screen and (max-width: 1000px) {
  main {
    width: 100%;
    padding-left: 5px;
  }

  nav li {
    display: inline-block;
    width: 30%;
  }
}
